<template>
    <div>
        <loading v-if="canSubmit" :loading="form.loading" spinner-class="justify-center">
            <form v-if="form.show || !form.data.id" @submit.prevent="submit">
                <div v-show="!loading" class="card p-3">
                    <h5 class="color-dark font-semibold">*Isi form di bawah ini untuk pengajuan sidang skripsi</h5>
                    <div class="mt-4">
                        <div class="form-group">
                            <label class="font-semibold">Judul Bahasa Indonesia</label><br>
                            <input class="form-control" v-model="form.data.judul_id" type="text" value="" autocomplete="off" required>
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Judul Bahasa Inggris</label><br>
                            <input class="form-control" v-model="form.data.judul_en" type="text" value="" autocomplete="off" required>
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">KTM</label><br>
                            <input-file :placeholder="placeholders.ktm ? placeholders.ktm : 'Masukan kartu tanda mahasiswa'" @change="(val) => form.data.ktm = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Jurnal (Acuan dan minimal 1 Pembanding )</label><br>
                            <input-file :placeholder="placeholders.jurnal ? placeholders.jurnal : 'Jurnal (Acuan dan minimal 1 Pembanding )'" @change="(val) => form.data.jurnal = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Dokumen Skripsi Lengkap</label><br>
                            <input-file :placeholder="placeholders.dokumen_lengkap ? placeholders.dokumen_lengkap : 'Dokumen Skripsi Lengkap'" @change="(val) => form.data.dokumen_lengkap = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Presentasi ( PPT File)</label><br>
                            <input-file :placeholder="placeholders.presentasi ? placeholders.presentasi : 'Presentasi ( PPT File )'" @change="(val) => form.data.presentasi = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Jurnal Lengkap Dengan Abstract ( INA-ENG)</label><br>
                            <input-file :placeholder="placeholders.jurnal_abstract ? placeholders.jurnal_abstract : 'Jurnal Lengkap Dengan Abstract ( INA-ENG)'" @change="(val) => form.data.jurnal_abstract = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Transkrip Nilai</label><br>
                            <input-file :placeholder="placeholders.transkrip_nilai ? placeholders.transkrip_nilai : 'Masukan Transkrip Nilai'" @change="(val) => form.data.transkrip_nilai = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Kartu Bimbingan </label><br>
                            <input-file :placeholder="placeholders.kartu_bimbingan ? placeholders.kartu_bimbingan : 'Masukan kartu bimbingan ( minimal 8x bimbingan )'" @change="(val) => form.data.kartu_bimbingan = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Bukti QR Code</label><br>
                            <input-file :placeholder="placeholders.bukti_qr ? placeholders.bukti_qr : 'Masukkan QR Code Dosen'" @change="(val) => form.data.bukti_qr = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">English Score</label><br>
                            <input-file :placeholder="placeholders.english_score ? placeholders.english_score : 'English Score dari ULS'" @change="(val) => form.data.english_score = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Pembayaran UPP</label><br>
                            <input-file :placeholder="placeholders.pembayaran_upp ? placeholders.pembayaran_upp : 'Pembayaran UPP s/d bulan berjalan.'" @change="(val) => form.data.pembayaran_upp = val" :required="true" />
                        </div>
                        <div class="form-group">
                            <label class="font-semibold">Hasil Turnutin</label><br>
                            <input-file :placeholder="placeholders.hasil_turnutin ? placeholders.hasil_turnutin : 'Hasil Turnitin. Maks 29%'" @change="(val) => form.data.hasil_turnutin = val" :required="true" />
                        </div>

                        <div class="flex justify-end pt-4">
                            <router-link :to="{ name: 'Skripsi' }" class="btn btn-outline-primary py-2">
                                Batal
                            </router-link>
                            <button type="submit" class="lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5 ml-3">
                                Ajukan Sidang
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div v-else>
                <div style="max-width: 700px">
                    <div v-if="form.data.status === 1" class="card p-5 text-center">
                        <h5>Status Pengajuan Sidang</h5>
                        <p class="sidang-status-waiting mt-3">“ Menunggu Konfirmasi “</p>
                    </div>
                    <div v-if="form.data.status === 2" class="card p-5 text-center">
                        <h5>Status Pengajuan Sidang</h5>
                        <p class="sidang-status-approved mt-3">“ Pengajuan Diterima “</p>
                        <p class="text-center">
                            Selamat pengajuan sidang kamu telah diterima, persiapkan dirimu untuk sidang pada: <br>
                            <strong>Hari/tanggal : {{ form.data.date ? formatDate(form.data.date) : '-' }} </strong>  <br>
                            <strong>Jam : {{ form.data.date ? formatTime(form.data.date) : '-' }}</strong>
                        </p>
                    </div>
                    <div v-if="form.data.status === 3" class="card p-5 text-center">
                        <h5>Status Pengajuan Sidang</h5>
                        <p class="sidang-status-rejected mt-3">“ Pengajuan Ditolak “</p>
                        <p>Mohon maaf pengajuan Sidang Kamu belum diterima, silahkan melakukan pengajuan kembali. Perbaiki sesuai dengan catatan di bawah ini</p>
                        <div class="sidang-reject-note mt-4">
                            {{ form.data.note }}
                        </div>
                        <div class="mt-4">
                            <a href="" @click.prevent="reSubmit" class="btn btn-primary">Ajukan Sekarang</a>
                        </div>
                    </div>
                </div>
            </div>
        </loading>

        <div v-else class="mt-a">
            <div class="card p-5">
                <h5 class="color-dark font-semibold text-center">*Kamu dapat mengajukan sidang skripsi setelah progress skripsi mencapai 100%</h5>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import InputFile from '@/components/InputFile.vue'

    export default {
        components: { InputFile },
        computed: {
            ...mapState('skripsi', ['data', 'loading']),
            canSubmit () {
                if (this.data.progress < 100) return false

                if (this.data && this.data.mentors) {
                    var hasUnfinished = false
                    this.data.mentors.forEach(mentor => {
                        if (!mentor.finished) hasUnfinished = true
                    })

                    if (hasUnfinished) return false
                }

                return true
            }
        },
        data () {
            return {
                form: {
                    show: false,
                    data: {
                        id: '',
                        judul_id: '',
                        judul_en: '',
                        ktm: null,
                        jurnal: null,
                        dokumen_lengkap: null,
                        presentasi: null,
                        jurnal_abstract: null,
                        transkrip_nilai: null,
                        kartu_bimbingan: null,
                        bukti_qr: null,
                        english_score: null,
                        pembayaran_upp: null,
                        hasil_turnutin: null,
                        status: 1,
                        date: '',
                        note: ''
                    },
                    loading: false
                },
                placeholders: {
                    ktm: '',
                    jurnal: '',
                    dokumen_lengkap: '',
                    presentasi: '',
                    jurnal_abstract: '',
                    transkrip_nilai: '',
                    kartu_bimbingan: '',
                    bukti_qr: '',
                    english_score: '',
                    pembayaran_upp: '',
                    hasil_turnutin: ''
                }
            }
        },
        methods: {
            submit () {
                this.form.loading = true
                var data = { ...this.form.data }

                if (!data.date) {
                    delete data.date
                }

                this.$store.dispatch('skripsi/createSidang', data)
                    .then(() => {
                        this.fetchSidangSkripsi()
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            reSubmit () {
                this.form.show = true
                this.placeholders = {
                    ktm: '',
                    jurnal: '',
                    dokumen_lengkap: '',
                    presentasi: '',
                    jurnal_abstract: '',
                    transkrip_nilai: '',
                    kartu_bimbingan: '',
                    bukti_qr: '',
                    english_score: '',
                    pembayaran_upp: '',
                    hasil_turnutin: ''
                }
            },
            getFileName (url) {
                return url.split('/').pop()
            },
            fetchSidangSkripsi () {
                this.fetchSidang()
                    .then(res => {
                        if (res) {
                            this.form.data.id = res.id
                            this.form.data.status = res.status
                            this.form.data.date = res.date
                            this.form.data.note = res.note
                            this.form.data.judul_id = res.judul_id
                            this.form.data.judul_en = res.judul_en
                            this.placeholders.ktm = this.getFileName(res.ktm)
                            this.placeholders.jurnal = this.getFileName(res.jurnal)
                            this.placeholders.dokumen_lengkap = this.getFileName(res.dokumen_lengkap)
                            this.placeholders.presentasi = this.getFileName(res.presentasi)
                            this.placeholders.jurnal_abstract = this.getFileName(res.jurnal_abstract)
                            this.placeholders.transkrip_nilai = this.getFileName(res.transkrip_nilai)
                            this.placeholders.kartu_bimbingan = this.getFileName(res.kartu_bimbingan)
                            this.placeholders.bukti_qr = this.getFileName(res.bukti_qr)
                            this.placeholders.english_score = this.getFileName(res.english_score)
                            this.placeholders.pembayaran_upp = this.getFileName(res.pembayaran_upp)
                            this.placeholders.hasil_turnutin = this.getFileName(res.hasil_turnutin)
                        }
                    })
            },
            ...mapActions('skripsi', { fetch: 'fetch', fetchSidang: 'showSidang' })
        },
        mounted () {
            this.fetchSidangSkripsi()
        }
    }
</script>
